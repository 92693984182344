import { useDispatch } from 'react-redux';
import { useWizard } from '@shared/ui/misc/Wizard';
import { useAppSelector } from '@app/store/Hooks';
import { resetFormValues, updateSidebarValues, } from '@entities/exchange';
import { ConnectExchangeSteps } from './steps/constants';
export var ConnectExchangeReqErrors;
(function (ConnectExchangeReqErrors) {
    ConnectExchangeReqErrors["UnknownError"] = "UNKNOWN_ERROR";
    ConnectExchangeReqErrors["ConnectionFailure"] = "CONNECTION_FAILURE";
    ConnectExchangeReqErrors["ApiKeyIsUsed"] = "API_KEY_IS_USED";
})(ConnectExchangeReqErrors || (ConnectExchangeReqErrors = {}));
const useConnectExchange = ({ handleClose: closeSidebarFn }) => {
    const { current, goTo, } = useWizard();
    const { sidebarFormValues } = useAppSelector(state => state.exchange);
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(resetFormValues());
        goTo(ConnectExchangeSteps.SelectExchange);
        closeSidebarFn();
    };
    const goToNextStep = (values) => {
        switch (current) {
            case 0:
                if (values) {
                    dispatch(updateSidebarValues(values));
                    goTo(ConnectExchangeSteps.ConnectExchange);
                }
                break;
            case 1:
                goTo(ConnectExchangeSteps.SelectWallet);
                break;
            case 2:
                if (values) {
                    dispatch(updateSidebarValues(values));
                    goTo(ConnectExchangeSteps.Success);
                }
                break;
            case 3:
                handleClose();
                break;
            case 5:
                handleClose();
                break;
            default:
                break;
        }
    };
    const goToPrevStep = () => {
        switch (current) {
            case 1:
                goTo(ConnectExchangeSteps.SelectExchange);
                break;
            case 2:
                goTo(ConnectExchangeSteps.ConnectExchange);
                break;
            default:
                break;
        }
    };
    return {
        sidebarFormValues,
        goToNextStep,
        goToPrevStep,
        handleClose,
        // TODO: add appropriate request and error handling
        apiError: ConnectExchangeReqErrors.UnknownError,
    };
};
export default useConnectExchange;
