export var ConnectExchangeSteps;
(function (ConnectExchangeSteps) {
    ConnectExchangeSteps[ConnectExchangeSteps["SelectExchange"] = 0] = "SelectExchange";
    ConnectExchangeSteps[ConnectExchangeSteps["ConnectExchange"] = 1] = "ConnectExchange";
    ConnectExchangeSteps[ConnectExchangeSteps["SelectWallet"] = 2] = "SelectWallet";
    ConnectExchangeSteps[ConnectExchangeSteps["Success"] = 3] = "Success";
    ConnectExchangeSteps[ConnectExchangeSteps["Error"] = 4] = "Error";
    ConnectExchangeSteps[ConnectExchangeSteps["SuccessReport"] = 5] = "SuccessReport";
})(ConnectExchangeSteps || (ConnectExchangeSteps = {}));
export const WALLET_TYPE_NAMES = {
    SPOT: 'SPOT',
    MARGIN: 'MARGIN',
    FUTURE: 'FUTURE',
    FUTURES1: 'FUTURES-1',
    FUTURES2: 'FUTURES-2',
};
